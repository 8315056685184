body {
  margin: 0;
  font-family: 'Baloo Da',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

}

.full-screen-image-container {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1; /* Adjust the z-index as needed */
}



/* Style for desktop view of img1 */
.desktop-img1 {
  width: 100vw;
  height: 100vw;
  object-fit: cover;
  object-position: center;
}

/* Style for desktop view of img2 */
.desktop-img2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Style for desktop view of img3 */
.desktop-img3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Style for mobile view of img1 */
.mobile-img1-container {
  width: 100%;
  height: 100%;
  background-image: url('../public/img1-mobile.png'); /* Mobile-optimized image for img1 */
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  display: none; /* Initially hide for desktop view */
}



/* Style for mobile view of img2 */
.mobile-img2-container {
  width: 100%;
  height: 100%;
  background-image: url('../public/img2-mobile.png'); /* Mobile-optimized image for img2 */
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  display: none; /* Initially hide for desktop view */
}

/* Style for mobile view of img3 */
.mobile-img3-container {
  width: 100%;
  height: 100%;
  background-image: url('../public/img3-mobile.png'); /* Mobile-optimized image for img3 */
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  display: none; /* Initially hide for desktop view */
}

/* Style for img1, img2, img3 on desktop view (hide them on smaller screens) */
.desktop-image {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}


.summon-text {
    text-align: center;
  }


  .summon-text + .summon-text {
    border-top: 5px solid gray;
    
  }


.summon-text h1 {
  font-size: 50px; /* Adjust the font size as needed */
  font-weight: bold;
  margin-bottom: 50px; /* Adjust the margin as needed */
  margin-top: 50px;
  line-height:100%;
}


.content-container {
  padding: 10px;
  /* margin-top: 100vh; Adjust the margin to make space for the image */
  z-index: 2;
  margin-top: 15px;
  margin-bottom: 0px;
}

 .button-container {
  position: absolute;
  bottom: 50px !important ;
  left: 50%;
  transform: translateX(-50%) ;
  display: flex;
  justify-content: space-around;
  padding: 20px;
  z-index: 2;
} 

.button-container button {
  width: 285px; /* Adjust the width as needed */
  height: 85px; /* Adjust the height as needed */
  font-size: 18px; /* Adjust the font size as needed */
  margin: 5px 60px; /* Adjust the margin as needed */
  background-image: url('../public/s1.png'); /* Replace 'path/to/your/image.png' with the actual path to your button image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 55px;
  font-family: 'Baloo Da'; /* Use the 'BalooDa' font */
  font-size: 20px; /* Adjust the font size as needed */
  color: rgb(54, 28, 5); /* Set the text color */ 
  font-weight: bold; 
  line-height: 65px;
   /* Adjust the left padding as needed */
   padding-left: 50px;
  padding-right: 20px; /* Adjust the right padding as needed */
}

.full-screen-image-container .button-container {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  padding: 20px;
  z-index: 2;
}


.desktop-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 280px; /* Adjust the distance from the bottom as needed */
  left: 50%;
  transform: translateX(-50%);
}

.desktop-button {
  width: 150px; /* Adjust the width for desktop buttons */
  height: 150px; /* Adjust the height for desktop buttons */
  font-size: 16px; /* Adjust the font size for desktop buttons */
  margin: 5px; /* Adjust the margin for desktop buttons */
  border-radius: 10px; /* Adjust the border-radius for desktop buttons */
  background-color: #007bff; /* Adjust the background color for desktop buttons */
  color: #fff; /* Adjust the text color for desktop buttons */
  border: none;
  outline: none;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#desktop-button1 {
  background-image: url('../public/tiktok-desktop.png');
  border-radius: 500px;
  
}

#desktop-button2 {
  background-image: url('../public/youtube-desktop.png');
  border-radius: 1678px;
  
}

#desktop-button3 {
  background-image: url('../public/discord-desktop.png');
  border-radius: 80px;
}

#desktop-button4 {
  background-image: url('../public/x-desktop.png');
  border-radius: 678px;

}

#desktop-button5 {
  background-image: url('../public/instagram-desktop.png');
  border-radius: 678px;
}

.button-on-image {
  display: none;
}

.container {
  margin-top: 100vh; /* Adjust the margin to make space for the image */
  padding: 20px; /* Add padding for better visibility of the container content */
  display: none; /* Initially hide the container */
  
}

#connect-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust the background color and opacity */
  z-index: 2; /* Place it above other content */
}



#connect-container button {
  margin-right: 20px;
}
.connect {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

/* .quantity-control {
  display: flex;
  justify-content: center;
  align-items: center;
  
  
} */

.full-screen-image-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure the image doesn't overflow */
}

.full-screen-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
  object-position: center; /* Center the image within the container */
}



.overlay-visible {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add these styles at the end of your existing CSS file */

.faq-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.faq-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
}

.faq-question {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.faq-answer {
  font-size: 16px;
  color: #555;
}

.faq-item:last-child {
  border-bottom: none;
}

.full-screen-image-container1 {
  position: relative;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px; /* Adjust the margin as needed */
}

#popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 3; /* Ensure the popup is above other content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#popup-transaction-hash,
#popup-nfts-minted,
#close-button {
  margin-bottom: 20px; /* Adjust the margin as needed */
}

#popup-transaction-hash,
#popup-nfts-minted {
  text-align: center;
  font-size: 16px; /* Adjust the font size as needed */
}

#close-button {
  margin-top: 20px; /* Adjust the margin-top as needed to center the button */
  text-align: center;
  font-size: 16px; /* Adjust the font size as needed */
  padding: 8px 18px;
  cursor: pointer;
  background-color: #007bff; /* Change the background color as needed */
  color: #fff; /* Change the text color as needed */
  border: none;
  border-radius: 5px;
  outline: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1000px) {

  /* Style for img1, img2, img3 on desktop view (hide them on smaller screens) */
  .desktop-image {
    display: none;
  }

  /* Style for stargaze image on desktop view (hide them on smaller screens) */
  .desktop-image2 {
    display: none;
  }


  .desktop-buttons {
    display: none;
  }

 

  /* Style for img1, img2, img3 on mobile view (show them on smaller screens) */
  .mobile-image {
    display: block;
  }

  .mobile-img1-container {
    display: block;
  }

  .mobile-img2-container {
    display: block;
  }

  .mobile-img3-container {
    display: block;
  }
  

  .full-screen-image-container .button-container {
    bottom: 0.5%; /* Adjust the distance from the bottom as needed */
  }



  .button-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 5px;
    bottom: 160px !important ;
  } 

   .button-container button {
    margin-bottom: 85px;
    width: 10px; 
    height: 80px; 
    font-size: 24px; 
    margin: 5px 20px; 
    border-radius: 55px;
    font-family: 'Baloo Da'
  } 

  .button-on-image {
    display: block;
    position: absolute;
    top: 30%; /* Adjust the top value as needed */
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  
  #button2 {
    top: 40%; /* Adjust the top value as needed */
  }
  
  #button3 {
    top: 50%; /* Adjust the top value as needed */
  }
  
  #button4 {
    top: 60%; /* Adjust the top value as needed */
  }
  
  #button5 {
    top: 70%; /* Adjust the top value as needed */
  }
  
  .button-on-image img {
    width: 250px;
    height: auto;
    cursor: pointer;
    margin-bottom: 40px;
  }

  .bottom-buttons-container {
    position: absolute; /* Change to absolute positioning */
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 80%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
  }
  .social-button {
    margin-bottom: 10px;
    width: 50px; /* Adjust button size for small screens */
    height: 50px;
  }
  
  .social-button i {
    font-size: 20px; /* Reduce icon size for smaller buttons */
    margin-right: 5px; /* Adjust margin for a smaller gap */
  }

  .quantity-control,
  .full-screen-image-container1 {
    padding: 20px;
  }

  .full-screen-image-container1 {
    text-align: center;
  }

  .bottom-buttons-container {
    position: static;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .bottom-buttons-container button {
    margin-bottom: 10px;
  }

  .social-button {
    margin-bottom: 10px;
    width: 50px; /* Adjust button size for small screens */
    height: 50px;
  }

  .video-container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    margin-bottom: 20px;
    width: 100%; /* Make the video container full-width on small screens */
  }

  .video-container iframe {
    width: 100%; /* Make the video iframe fill the width of its container */
    max-width: 100%; /* Ensure the video iframe doesn't exceed its container */
    height: 300px; /* Allow the video iframe to maintain its aspect ratio */
  }
}
.App {
  text-align: center;
  margin-bottom: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.quantity-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Adjust the spacing as needed */
  margin-top: 20px; /* Add margin as needed */
}


.quantity-buttons {
  display: flex;
  gap: 10px; /* Adjust the gap between buttons as needed */
  
}

/* .quantity-button {
  display: flex;
  gap: 10px;
}*/

.increase-button {
  margin-right: 0;
} 

.quantity-button,
#mintButton {
  width: 40px;
  height: 40px;
  font-size: 18px;
  background-color: #e0e0e0;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Baloo Da';
}

#mintButton {
  margin-left: 10px; /* Add margin to separate the mint button and increase button */
}

.mint-container {
  margin-top: 20px; /* Add margin to separate the quantity buttons and mint button */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the mint button */
  outline: 2px solid #000; /* Add an outline to the container */
  padding: 10px; /* Add padding for better visibility */
}


.mintButton {
  margin-top: 10px;
  width: 200px; /* Adjust the width of the mint button */
  height: 40px;
  font-size: 16px;
  
  
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container button {
  width: 285px; /* Adjust the width for other buttons as needed */
}

#mintButton {
  width: 285px; /* Adjust the width for the mint button */
  margin-top: 20px; /* Adjust the gap between quantity buttons and mint button */
  background-color: #0ca4eb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
